<template>
  <div v-loading="loading" class="eachrts">
    <div id="main" style="width: 100%; height: 400px"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { findPromotionUserStat } from '@/api/card'
export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      date: [],
      data: [],
      loading: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      this.date = []
      this.data = []
      console.log(this.form)
      findPromotionUserStat(this.form).then((res) => {
        this.loading = false
        res.data.forEach((e) => {
          this.date.push(e.createDate)
          this.data.push(e.userCount)
        })
        this.getCharts()
      })
    },
    getCharts() {
      const chartDom = document.getElementById('main')
      const myChart = echarts.init(chartDom)
      const option = {
        grid: {
          left: '6%',
          bottom: '10%',
          right: '6%',
        },
        xAxis: {
          type: 'category',
          data: this.date,
          nameLocation: 'end',
          name: '时间',
          nameTextStyle: {
            fontSize: 14,
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: 'value',
          name: '推广注册人数',
          nameTextStyle: {
            fontSize: 14,
            padding: 20,
          },
        },
        series: [
          {
            data: this.data,
            type: 'line',
            label: {
              position: 'top',
              show: true,
            },
          },
        ],
      }

      myChart.setOption(option)
    },
  },
}
</script>

<style lang="less" scoped>
.eachrts {
  background-color: #fff;
  margin-top: 20px;
  padding: 16px 20px;
}
</style>
