<template>
  <div class="header">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <div class="header-search">
        <el-form-item label="">
          <el-radio-group v-model="form.radio">
            <el-radio :label="1">本月</el-radio>
            <el-radio :label="2">上月</el-radio>
            <el-radio :label="3">本季</el-radio>
            <el-radio :label="4">自定义</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-button type="primary" size="small" @click="mySpreadCode">我的推广码</el-button>
      </div>
      <el-form-item v-if="form.radio == 4" label="">
        <el-date-picker
          v-model="form.time"
          size="small"
          type="datetimerange"
          range-separator="至"
          style="width: 340px"
          :default-time="['00:00:00', '23:59:59']"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="form.uniPlatform" size="small" placeholder="请选择平台">
          <el-option
            v-for="item in platformOptions"
            :key="item.dictionaryValue"
            :label="item.dictionaryName"
            :value="item.dictionaryValue"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" class="fa">
        <el-input
          v-model="form.saleUserDepartmentName"
          v-clickoutside="inpClick"
          size="small"
          style="width: 240px"
          :placeholder="!isShow ? '请选择部门' : flag"
          :suffix-icon="!isShow ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          @focus="inpFocus"
        >
        </el-input>
        <tree
          v-show="isShow"
          :value="form.saleUserDepartmentName"
          :is-show="isShow"
          :flag="flag"
          class="tree"
          @ok="ok"
        />
      </el-form-item>

      <el-form-item label="">
        <el-input
          v-model="form.saleUserName"
          size="small"
          style="width: 240px"
          placeholder="请选择员工"
          readonly
        >
          <el-link slot="suffix" :underline="false" type="primary" @click="dialogVisible = true"
            >点击选择员工</el-link
          >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
        <el-button size="small" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <selectStaff
      :dialog-visible.sync="dialogVisible"
      :select-data.sync="selectData"
      @ok="selectStaffOk"
    />
    <el-dialog title="我的推广码" :visible.sync="showSpreadCode" width="600px">
      <div class="share-code">
        <div v-for="(item, index) in shareCodeList" :key="index" class="share-code__item">
          <div class="share-code__title">{{ item.uniPlatformName }}</div>
          <div class="share-code__box">
            <el-image
              class="share-code__img"
              :class="item.showBtn && 'share-code__img--no'"
              :src="
                item.codeUrl ||
                'https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/gh_4b377686955f_430.jpg'
              "
              fit="cover"
            ></el-image>
            <el-button type="text" @click="createShareCode(item)">{{
              item.showBtn ? '生成' : '下载'
            }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tree from './components/tree.vue'
import selectStaff from './components/selectStaff.vue'
import moment from 'moment'
import { clickoutside } from '@/utils/way'
import { getDictionaryListByKey } from '@/api/dectionary.js'
import { findShareCodeListByUserId, generateShareCode } from '@/api/index.js'

export default {
  name: 'PageHeader',
  components: {
    tree,
    selectStaff,
  },
  directives: { clickoutside },
  data() {
    return {
      form: {
        radio: 1,
        saleUserDepartmentId: '',
        saleUserDepartmentName: '',
        uniPlatform: '',
      },
      isShow: false,
      dialogVisible: false,
      flag: '',
      selectData: [],
      platformOptions: [],
      showSpreadCode: false,
      shareCodeList: [],
    }
  },
  watch: {
    'form.radio': {
      deep: true,
      handler(val) {
        switch (val) {
          case 1:
            this.form.createTimeStart = moment().startOf('month').format('YYYY-MM-DD 00:00:00')
            this.form.createTimeEnd = moment().format('YYYY-MM-DD 23:59:59')
            break
          case 2:
            this.form.createTimeStart = moment().subtract(1, 'months').format('YYYY-MM-01 00:00:00')
            this.form.createTimeEnd = moment()
              .month(moment().month() - 1)
              .endOf('month')
              .format('YYYY-MM-DD 23:59:59')
            break
          case 3:
            this.form.createTimeStart = moment().startOf('quarter').format('YYYY-MM-DD 00:00:00')
            this.form.createTimeEnd = moment().endOf('quarter').format('YYYY-MM-DD 23:59:59')
            break
          default:
            break
        }
      },
    },
  },
  mounted() {
    this.form.createTimeStart = moment().startOf('month').format('YYYY-MM-DD 00:00:00')
    this.form.createTimeEnd = moment().format('YYYY-MM-DD 23:59:59')
    this.getDictionaryList()
    this.getShareCodeList()
  },
  methods: {
    createShareCode(item) {
      console.log(item)
      if (item.showBtn) {
        generateShareCode({
          data: {
            uniPlatform: item.uniPlatform,
          },
        }).then((res) => {
          console.log(res)
          this.getShareCodeList()
        })
        return
      }

      window.open(item.codeUrl, 'a1')
    },
    mySpreadCode() {
      this.showSpreadCode = true
    },
    getShareCodeList() {
      findShareCodeListByUserId({ data: {} }).then((res) => {
        console.log(res)
        this.shareCodeList = res.data[0].shareCodeList
      })
    },
    getDictionaryList() {
      getDictionaryListByKey({
        data: {
          key: 'uni_platform',
        },
      }).then((res) => {
        console.log(res)
        this.platformOptions = res.data.childs
      })
    },
    inpFocus() {
      this.isShow = true
      this.form.saleUserDepartmentId = ''
      this.form.saleUserDepartmentName = ''
    },
    inpClick() {
      this.isShow = false
      if (this.flag) this.form.saleUserDepartmentName = this.flag
    },
    onSubmit() {
      const data = { ...this.form }
      if (!!data.time && this.form.radio === 4) {
        data.createTimeStart = moment(data.time[0]).format('YYYY-MM-DD HH:mm:ss')
        data.createTimeEnd = moment(data.time[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      delete data.time
      delete data.radio
      delete data.saleUserDepartmentName

      this.$emit('search', data)
    },
    reset() {
      this.form = {
        radio: 1,
        saleUserDepartmentId: '',
        saleUserDepartmentName: '',
        createTimeStart: moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
        createTimeEnd: moment().format('YYYY-MM-DD 23:59:59'),
        uniPlatform: '',
      }
      const data = { ...this.form }
      delete data.radio
      delete data.saleUserDepartmentName
      this.flag = ''
      this.selectData = []
      this.$emit('search', data)
    },

    selectStaffOk(data) {
      this.selectData = data
      this.form.saleUserId = data[0].userId
      this.form.saleUserName = data[0].userName
    },
    ok(data) {
      this.form.saleUserDepartmentId = data.departmentId
      this.form.saleUserDepartmentName = data.departmentName
      this.flag = data.departmentName
      this.isShow = false
    },
  },
}
</script>
<style lang="less" scoped>
::v-deep .el-radio-group {
  .el-radio__input {
    display: none;
  }
}
.el-link {
  font-size: 12px;
}
.fa {
  position: relative;
  .tree {
    position: absolute;
  }
}
.header-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.share-code {
  display: flex;
  flex-wrap: wrap;
  &__item {
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 116px;
  }
  &__title {
    text-align: center;
    color: #666666;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  &__box {
    width: 116px;
    height: 136px;
    border-radius: 4px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 16px 0 16px;
    box-sizing: border-box;
  }
  &__img {
    width: 84px;
    height: 84px;
    flex-shrink: 0;
    &--no {
      opacity: 0.1;
    }
  }
}
</style>
