<template>
  <div>
    <div v-if="!showDetail">
      <page-header @search="search" />
      <Echarts ref="echarts" :form="echartForm" />
      <div class="table">
        <div class="btn">
          <el-button size="small" @click="exportTable">导出</el-button>
        </div>
        <Table ref="table" :table-data="tableData" :total="total" :loading="loading" :form="form" />
        <Pages
          :current-page="form.pageNumber"
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import pageHeader from './header'
import Table from './table'
import Pages from '@/components/pages'
import { findPromotionUserPageList } from '@/api/card'
import { downloadFile } from '@/utils/fileDownload'
import Echarts from './echarts.vue'
import moment from 'moment'
export default {
  components: {
    pageHeader,
    Table,
    Pages,
    Echarts,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      multipleSelection: [],
      isEnable: 1,
      showDetail: false,
      echartForm: { data: {} },
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {},
      },
      loading: false,
      row: {},
      radio: '',
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  watch: {},
  created() {
    this.echartForm.data.createTimeStart = moment().startOf('month').format('YYYY-MM-DD 00:00:00')
    this.echartForm.data.createTimeEnd = moment().format('YYYY-MM-DD 23:59:59')
  },
  mounted() {
    this.form.data.createTimeStart = moment().startOf('month').format('YYYY-MM-DD 00:00:00')
    this.form.data.createTimeEnd = moment().format('YYYY-MM-DD 23:59:59')

    this.getTableData()
  },
  methods: {
    getTableData() {
      this.loading = true
      findPromotionUserPageList(this.form).then((res) => {
        this.loading = false
        this.tableData = res.data.content
        this.tableData.forEach((e) => {
          for (const n in e) {
            if (!e[n]) e[n] = '--'
          }
        })
        this.total = Number(res.data.total)
      })
    },
    search(data) {
      this.form.pageNumber = 1
      this.form.data = data
      this.echartForm.data = data
      this.$refs.echarts.getData()
      this.getTableData()
    },

    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },

    exportTable() {
      const data = JSON.parse(JSON.stringify(this.form))
      data.data.export = 1
      this.loading = true
      findPromotionUserPageList(data).then((res) => {
        this.loading = false
        downloadFile(res, '用户注册统计')
      })
    },
  },
}
</script>
<style lang="less" scoped></style>
