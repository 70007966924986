<template>
  <el-dialog
    title="选择员工"
    :visible.sync="dialogVisible"
    width="900px"
    :before-close="handleClose"
  >
    <div>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="">
          <el-input
            v-model="form.phoneNumber"
            size="small"
            style="width: 150px"
            placeholder="请输入员工手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="form.userId"
            size="small"
            style="width: 150px"
            placeholder="请输入员工ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="form.userNameOrPhoneNumber"
            size="small"
            style="width: 150px"
            placeholder="请输入员工姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-select
            v-model="form.post"
            size="small"
            filterable
            style="width: 150px"
            placeholder="请选择岗位"
          >
            <el-option
              v-for="(n, i) in postList"
              :key="i"
              :label="n.postName"
              :value="n.postId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
          <el-button type="primary" size="small" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="tree">
        <el-tree
          ref="tree"
          v-loading="loading"
          class="filter-tree"
          :data="data"
          :props="defaultProps"
          show-checkbox
          default-expand-all
          check-strictly
          node-key="myId"
          @check-change="handleCheckChange"
        >
        </el-tree>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="ok">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getPostPage } from '@/api'
import { findDepartmentUserTreeByCondition } from '@/api/card'

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    selectData: {
      type: [Array],
      default: () => [],
    },
  },
  data() {
    return {
      form: {},
      tableData: [],
      postList: [],
      data: [],
      defaultProps: {
        children: 'childrenList',
        label: 'departmentName',
      },
      loading: false,
      currentNodeData: {},
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.data = []
        this.form = {}
        this.getTreeData()
      }
    },
  },
  mounted() {
    getPostPage({ pageNumber: 1, pageSize: 99, data: { isDisable: 0 } }).then((res) => {
      this.postList = res.data.content
    })
  },
  methods: {
    handleCheckChange(data, checked, tree) {
      if (checked) this.$refs.tree.setCheckedKeys([data.myId])
    },

    getTreeData() {
      this.loading = true
      findDepartmentUserTreeByCondition({
        data: {
          isResign: 0,
          isDisable: 0,
          ...this.form,
          post: this.form.post ? [this.form.post] : undefined,
        },
      }).then((res) => {
        this.data = res.data
        this.filterTree(this.data)
        if (this.selectData.length > 0) {
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys([this.selectData[0].myId])
          })
        } else {
          this.$refs.tree.setCheckedKeys([])
        }
        this.loading = false
      })
    },
    filterTree(arr) {
      arr.forEach((e) => {
        if (!!e.userList && e.userList.length > 0) {
          e.userList.forEach((v) => {
            v.myId = v.userId + '/' + e.departmentId
            v.departmentName = v.userName
          })
          e.childrenList = e.childrenList.concat(e.userList)
        }
        if (e.childrenList) {
          e.disabled = true
          this.filterTree(e.childrenList)
        }
      })
    },
    handleClose() {
      this.$emit('update:dialogVisible', false)
    },
    ok() {
      console.log(this.$refs.tree.getCheckedNodes())
      this.$emit('ok', this.$refs.tree.getCheckedNodes())
      this.$emit('update:dialogVisible', false)
    },
    onSubmit() {
      this.getTreeData()
    },
    reset() {
      this.form = {}
      this.getTreeData()
    },
  },
}
</script>

<style lang="less" scoped>
.el-form-item {
  margin-right: 10px !important;
}
.tree {
  width: 100%;
  max-height: 300px;
  box-sizing: border-box;
  .el-tree {
    max-height: 240px;
    overflow-y: scroll;
  }
  .el-tree::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }
  .el-tree::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 50px;
  }
  /*  滚动条轨道. */
  .el-tree::-webkit-scrollbar-track {
    background-color: #fff;
  }
  padding: 10px 2px;
  background-color: #fff;
  z-index: 99;
  border-radius: 3px;
}
</style>
