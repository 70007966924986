<template>
  <el-table ref="multipleTable" v-loading="loading" :data="tableData" border style="width: 100%">
    <el-table-column label="序号" min-width="80">
      <template slot-scope="scope">
        <span>{{ scope.$index + 1 + (form.pageNumber - 1) * form.pageSize }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="createTime" label="注册时间" min-width="160"> </el-table-column>
    <el-table-column prop="phoneNumber" label="注册用户手机号" width="180"> </el-table-column>
    <el-table-column prop="userName" label="注册用户姓名" width="180"> </el-table-column>
    <el-table-column prop="userId" label="注册用户ID" min-width="120"> </el-table-column>
    <el-table-column prop="saleUserName" label="员工姓名" min-width="180"> </el-table-column>
    <el-table-column prop="saleUserId" label="员工ID" min-width="120"> </el-table-column>
    <el-table-column prop="saleUserDepartmentName" label="所属部门" min-width="180">
    </el-table-column>
    <el-table-column prop="saleUserDepartmentId" label="部门ID" min-width="120"> </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="less" scoped></style>
